// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.easy-button-button {
	margin: 0;
    padding: 0;
	border: 0;
	background-color: #fff;
}

span.button-state {
	width: 30px;
	height: 30px;
	background-color: #fff;
	border: none;
}

span.button-state img {
	width: 30px;
    height: 30px;
}

span.measure-active-active {
	background-color: transparent;
}

button.measure-inactive-active {
	padding: 2px;
}
button.measure-active-active {
	padding: 2px;
	background-color: #63949F;
}

span.measure-inactive-active img {
	width: 25px;
	height: 25px;
}
span.measure-active-active img {
	width: 25px;
	height: 25px;
	background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./assets/styles/leaflet-easybutton.css"],"names":[],"mappings":"AAAA;CACC,SAAS;IACN,UAAU;CACb,SAAS;CACT,sBAAsB;AACvB;;AAEA;CACC,WAAW;CACX,YAAY;CACZ,sBAAsB;CACtB,YAAY;AACb;;AAEA;CACC,WAAW;IACR,YAAY;AAChB;;AAEA;CACC,6BAA6B;AAC9B;;AAEA;CACC,YAAY;AACb;AACA;CACC,YAAY;CACZ,yBAAyB;AAC1B;;AAEA;CACC,WAAW;CACX,YAAY;AACb;AACA;CACC,WAAW;CACX,YAAY;CACZ,6BAA6B;AAC9B","sourcesContent":["button.easy-button-button {\r\n\tmargin: 0;\r\n    padding: 0;\r\n\tborder: 0;\r\n\tbackground-color: #fff;\r\n}\r\n\r\nspan.button-state {\r\n\twidth: 30px;\r\n\theight: 30px;\r\n\tbackground-color: #fff;\r\n\tborder: none;\r\n}\r\n\r\nspan.button-state img {\r\n\twidth: 30px;\r\n    height: 30px;\r\n}\r\n\r\nspan.measure-active-active {\r\n\tbackground-color: transparent;\r\n}\r\n\r\nbutton.measure-inactive-active {\r\n\tpadding: 2px;\r\n}\r\nbutton.measure-active-active {\r\n\tpadding: 2px;\r\n\tbackground-color: #63949F;\r\n}\r\n\r\nspan.measure-inactive-active img {\r\n\twidth: 25px;\r\n\theight: 25px;\r\n}\r\nspan.measure-active-active img {\r\n\twidth: 25px;\r\n\theight: 25px;\r\n\tbackground-color: transparent;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
